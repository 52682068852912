import { ref, computed, reactive, toRaw, nextTick } from "vue";
import API from "@/API/taskCenter/index";

const useTaskCenter = () => {
  const columns = ref([
    {
      title: "任务来源",
      dataIndex: "taskSource",
      width: 80,
    },
    {
      title: "任务类型",
      dataIndex: "taskType",
      width: 80,
    },
    {
      title: "操作时间",
      dataIndex: "operTime",
      width: 100,
    },
    {
      title: "任务开始时间",
      dataIndex: "taskStartTime",
      width: 100,
    },
    {
      title: "任务完成时间",
      dataIndex: "taskEndTime",
      width: 100,
    },
    {
      title: "状态",
      dataIndex: "status",
      width: 100,
    },
    {
      title: "描述",
      dataIndex: "taskDesc",
      width: 100,
    },
    {
      title: "操作",
      width: 40,
      slots: { customRender: "operation" },
    },
  ]);
  const query = reactive({
    status: undefined,
    taskType: undefined,
  });
  const date = ref();
  const page = reactive({
    current: 1,
    size: 10,
  });
  const total = ref(0);
  // 任务类型
  const taskTypeRes = ref();
  const taskTypeList = computed(() => {
    return taskTypeRes.value;
  });
  const getTaskTypeList = () => {
    API.getTaskType().then((res: any) => {
      taskTypeRes.value = res;
    });
  };
  // 状态
  const taskStatusRes = ref();
  const taskStatusList = computed(() => {
    return taskStatusRes.value;
  });
  const getTaskStatusList = () => {
    API.getTaskStatus().then((res: any) => {
      taskStatusRes.value = res;
    });
  };
  // 列表
  const tableRes = ref();
  const tableData = computed(() => {
    return tableRes.value?.content;
  });
  const getTableData = () => {
    const _date = {
      operTimeFrom: undefined,
      operTimeTo: undefined,
    };
    if (date.value) {
      _date.operTimeFrom = date.value[0];
      _date.operTimeTo = date.value[1];
    }
    const _page = {...toRaw(page)}
    _page.current = _page.current-1
    API.fetchListPage({
      ...toRaw(query),
      ..._page,
      ..._date,
    }).then((res: any) => {
      tableRes.value = res;
      total.value = res.totalElements;
    });
  };
  return {
    query,
    date,
    page,
    total,
    columns,
    getTableData,
    tableData,
    taskTypeList,
    getTaskTypeList,
    taskStatusList,
    getTaskStatusList,
  };
};
export default useTaskCenter;
