
import { defineComponent, reactive, ref, toRefs } from "vue";
import carLicenseUpload from "@/views/System/TaskCenter/component/carLicenseUpload.vue";
import useTaskCenter from "@/hooks/rv/useTaskCenter";
const tableHeight = window.innerHeight - 200;
export default defineComponent({
  components: { carLicenseUpload },
  setup() {
    const {
      tableData,
      getTableData,
      columns,
      taskTypeList,
      getTaskTypeList,
      taskStatusList,
      getTaskStatusList,
      query,
      date,
      page,
      total,
    } = useTaskCenter();
    const init = () => {
      getTaskTypeList();
      getTaskStatusList();
      getTableData();
    };
    init();
    //#region [Actions]
    const search = () => {
      getTableData();
    };
    const reset = () => {
      query.status = undefined;
      query.taskType = undefined;
      date.value = undefined;
      page.size = 10;
      page.current = 1;
      getTableData();
    };
    const handlePageChange = (current: number, size: number) => {
      page.current = current;
      page.size = size;
      getTableData();
    };
    const handleSizeChange = (current: number, size: number) => {
      console.log(current);
      page.current = current;
      page.size = size;
      getTableData();
    };
    // 点击detail
    const modalVisible = ref(false);
    const curId = ref();
    const time = ref();
    const handleDetailShow = (record: any) => {
      modalVisible.value = true;
      curId.value = record.id;
      time.value = new Date().toString();
    };
    //#endregion

    return {
      search,
      reset,
      ...toRefs(query),
      ...toRefs(page),
      total,
      date,
      columns,
      getTableData,
      taskTypeList,
      taskStatusList,
      tableData,
      tableHeight,
      modalVisible,
      curId,
      time,
      handlePageChange,
      handleSizeChange,
      handleDetailShow,
    };
  },
});
